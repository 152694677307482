import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class UiUtilityService {
    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService
    ) {}

    /* common method which we use to show snackbar in portal */
    showSnackBar(message: string, action: string, duration: number): void {
        this.snackBar.open(message, action, {
          duration: duration, // Snackbar disappears after 3 seconds
          horizontalPosition: 'end', // Moves it to the right
          verticalPosition: 'top', // Moves it to the top
          panelClass: ['custom-snackbar'] // Apply custom styles
        });

    }

    onSuccess(message?: string | null): void {
        if (!message) {
            message = 'Request Successful';
        }

        this.showSnackBar(message, 'Close', 5000);
    }

    handleError(message?: string | null): void {
        if (!message) {
            message = 'Something went wrong! Please try again later.';
        }
        this.showSnackBar(message, 'Close', 5000);
    }

    getTranslation(key: string): string {
        return this.translate.instant(key);
    }

    getMultipleTranslations(keys: string[]): Promise<Record<string, string>> {
        return new Promise((resolve) => {
            this.translate.use('en-US').subscribe(() => {
                this.translate.get(keys).subscribe((values) => {
                    resolve(values);
                });
            });
        });
    }

    scrollToTopOfComponent(id: string): void {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.error('Element not found');
        }
    }

    /* common method which we use to check page index after deleting any record in portal */
    checkPageIndexAfterDelete(totalRecords: number, paginator: MatPaginator): MatPaginator {
        if (totalRecords > 0) {
            const recordsPerPage = (totalRecords - 1) / paginator.pageSize;
            if (recordsPerPage <= paginator.pageIndex + 1) {
                paginator.pageIndex = paginator.pageIndex - 1;
            }
        }

        return paginator;
    }
}
